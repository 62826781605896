import { Button } from 'carbon-components-react';
import '../../../../common/style/common.scss';
import NoteDrawer from './NoteDrawer/NoteDrawer';
import { ArrowRight16 } from '@carbon/icons-react';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { segmentsTracking } from 'src/common/lib/tracker';

export interface OmniChannelProps {
  id: string;
  omniChannel: boolean;
  setUrxForm: React.Dispatch<React.SetStateAction<boolean>>;
  setOmniChannel: React.Dispatch<React.SetStateAction<boolean>>;
  environment: string;
  instanceId: string;
  formId: string;
  urxlang: IBMLocale;
  bpid?: string;
  pageTitle: string;
  object: string;
  location: string;
  resultValue?: string;
  ctaValue?: string;
  action?: string;
  field?: string;
  tiles: string;
  setCookie: () => void;
}

const OmniChannel = ({
  id,
  omniChannel,
  setUrxForm,
  setOmniChannel,
  environment,
  instanceId,
  formId,
  urxlang,
  bpid,
  pageTitle,
  object,
  resultValue = '',
  ctaValue = '',
  action = '',
  field = '',
  location,
  tiles,
  setCookie,
}: OmniChannelProps) => {
  return (
    <div className="omni-channel">
      <Button
        className="guardium-insights__bluehighlight"
        kind="primary"
        renderIcon={ArrowRight16}
        id="request_btn"
        onClick={() => {
          segmentsTracking('CTA Clicked', {
            pageTitle: pageTitle,
            object: 'Pricing page',
            resultValue: 'CTA clicked',
            CTA: 'omnichannel slider closed',
            location: 'Estimator',
            action: '',
            field: '',
          });
          setOmniChannel(true);
          setUrxForm(false);
          setCookie();
        }}
      >
        Buy Now
      </Button>
      <NoteDrawer
        id={id}
        omniChannel={omniChannel}
        setOmniChannel={setOmniChannel}
        setUrxForm={setUrxForm}
        environment={environment}
        instanceId={instanceId}
        formId={formId}
        moreinformation={false}
        urxlang={urxlang}
        bpid={bpid}
        pageTitle={pageTitle}
        object={object}
        location={location}
        tiles={tiles}
        setCookie={setCookie}
      />
    </div>
  );
};

export default OmniChannel;
