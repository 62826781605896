import React from 'react';
import { Button, InlineNotification } from 'carbon-components-react';
import { ArrowRight16, Subtract32 } from '@carbon/icons-react';
import './NoteDrawer.scss';
import '../../NS1PriceEstimator.scss';
import omniChannels from '../omnichannel.json';
import awsimage from '../public/aws.png';
import ibmimage from '../public/ibm.png';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { segmentsTracking } from 'src/common/lib/tracker';

export interface NoteDrawer {
  omniChannel: boolean;
  setOmniChannel: React.Dispatch<React.SetStateAction<boolean>>;
  setUrxForm: React.Dispatch<React.SetStateAction<boolean>>;
  moreinformation: boolean;
  bpName?: string;
  logo?: string;
  id: string;
  environment: string;
  instanceId: string;
  formId: string;
  urxlang: IBMLocale;
  bpid?: string;
  pageTitle: string;
  object: string;
  location: string;
  resultValue?: string;
  ctaValue?: string;
  action?: string;
  field?: string;
  tiles: string;
  setCookie: () => void;
}

export default function NoteDrawer({
  id,
  omniChannel,
  setUrxForm,
  setOmniChannel,
  environment,
  urxlang,
  formId,
  instanceId,
  bpid,
  pageTitle,
  object,
  resultValue = '',
  ctaValue = '',
  action = '',
  field = '',
  location,
  tiles,
  setCookie,
}: NoteDrawer) {
  console.log(omniChannels);

  const bannerStartDate = new Date(2024, 10, 1);
  const bannerExpDate = new Date(2024, 10, 31);
  const today = new Date();

  const closeDrawer = () => {
    setOmniChannel(false);
    segmentsTracking('CTA Clicked', {
      pageTitle: pageTitle,
      object: object,
      resultValue: 'CTA clicked',
      CTA: 'ominichannel slider closed',
      location: location,
      action: action,
      field: field,
    });
  };

  return (
    <div id={id} className="ns1_pe">
      <div
        className={`widget-drawer-backdrop ${
          omniChannel ? 'opened' : 'closed'
        } widget-drawer-transition`}
        onClick={closeDrawer}
      ></div>
      <div
        className={`widget-drawer ${
          omniChannel ? 'opened' : 'closed'
        } widget-drawer-transition`}
      >
        <div className="widget-drawer-inner-container">
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
              <div className="widget-drawer-close-container">
                <Button
                  className="widget-drawer-close-btn"
                  kind="ghost"
                  renderIcon={Subtract32}
                  onClick={closeDrawer}
                ></Button>
              </div>
            </div>
          </div>
          <div className="widget-drawer-notes-container">
            <div className="bx--row">
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                <p className="slide_text widgetname">NS1 Connect</p>
              </div>
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                <p className="page_sub_heading">Buy yours now!</p>
              </div>
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 mt-02">
                <p className="page_body">
                  IBM NS1 Connect Managed DNS and traffic steering plans are
                  priced based upon query volume and DNS records. Our NS1
                  Essentials plan is available through IBM Marketplace and AWS
                  Marketplace. We also offer a developer account at no charge
                  for companies looking to try the solution first.
                </p>
              </div>
              <div className="cta-section">
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                  <img alt="" title="" src={ibmimage} />
                </div>
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                  <p className="page_body cta-text">
                    {today <= bannerExpDate && today >= bannerStartDate
                      ? 'Add resilient, high performing DNS with IBM NS1 Connect in November and get 30% off.'
                      : 'Fastest way to buy NS1 Connect in a few simple clicks.'}
                  </p>
                  <Button
                    className="guardium-insights__bluehighlight"
                    kind="primary"
                    renderIcon={ArrowRight16}
                    id="request_btn"
                    onClick={() => {
                      segmentsTracking('CTA Clicked', {
                        pageTitle: pageTitle,
                        object: object,
                        resultValue: 'CTA clicked - Omnichannel',
                        CTA: 'ibm marketplace - deeplink',
                        location: location,
                        action: action,
                        field: field,
                      });
                      console.log(`Tiles - ${tiles}`);
                      const editionId = 'EIDF9I49';
                      // if (tiles === 'Standard') {
                      //   editionId = '-';
                      // } else if (tiles === 'Essentials') {
                      //   editionId = 'EIDXX9VC';
                      // }
                      // https://www.ibm.com/marketplace/purchase/configuration/en/us/edition?editionID=EIDF9I49
                      // https://www.ibm.com/marketplace/purchase/configuration/en/en/checkout?editionID=${editionId}&pricingtablecta=true
                      window.open(
                        `https://www.ibm.com/marketplace/purchase/configuration/en/us/checkout?editionID=${editionId}&pricingtablecta=true`,
                        '_blank',
                      );
                    }}
                  >
                    Buy now
                  </Button>
                </div>
              </div>
              <div className="cta-section">
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                  <img alt="" title="" src={awsimage} />
                </div>
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                  <p className="page_body cta-text">
                    If you are already an AWS customer, buy through AWS to use
                    your existing credits
                  </p>
                  <Button
                    kind="tertiary"
                    renderIcon={ArrowRight16}
                    id="request_btn"
                    onClick={() => {
                      segmentsTracking('CTA Clicked', {
                        pageTitle: pageTitle,
                        object: object,
                        resultValue: 'CTA clicked - Omnichannel',
                        CTA: 'aws marketplace - deeplink',
                        location: location,
                        action: action,
                        field: field,
                      });
                      window.open(
                        'https://aws.amazon.com/marketplace/pp/prodview-mbjt4bsdjr5gg?sr=0-1&ref_=beagle&applicationId=AWSMPContessa',
                        '_blank',
                      );
                    }}
                  >
                    Buy now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
