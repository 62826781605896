import { Button } from 'carbon-components-react';
import '../../../../common/style/common.scss';
import '../NS1PriceEstimator.scss';
import NoteDrawerRequest from './NoteDrawerRequest/NoteDrawerRequest';
import { ArrowRight16 } from '@carbon/icons-react';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { segmentsTracking } from 'src/common/lib/tracker';

export interface OmniChannelRequestProps {
  id: string;
  OmniChannelRequest: boolean;
  setUrxForm: React.Dispatch<React.SetStateAction<boolean>>;
  setOmniChannelRequest: React.Dispatch<React.SetStateAction<boolean>>;
  environment: string;
  instanceId: string;
  formId: string;
  formId2: string;
  urxlang: IBMLocale;
  bpid?: string;
  pageTitle: string;
  object: string;
  location: string;
  resultValue?: string;
  ctaValue?: string;
  action?: string;
  field?: string;
  tiles: string;
  setCookie: () => void;
}

const OmniChannelRequest = ({
  id,
  OmniChannelRequest,
  setUrxForm,
  setOmniChannelRequest,
  environment,
  instanceId,
  formId,
  formId2,
  urxlang,
  bpid,
  pageTitle,
  object,
  resultValue = '',
  ctaValue = '',
  action = '',
  field = '',
  location,
  tiles,
  setCookie,
}: OmniChannelRequestProps) => {
  return (
    <div className="omni-channel">
      <Button
        className="ns1_pe__bluehighlight"
        kind="tertiary"
        renderIcon={ArrowRight16}
        id="request_btn"
        onClick={() => {
          segmentsTracking('CTA Clicked', {
            pageTitle: pageTitle,
            object: 'Pricing page',
            resultValue: 'CTA clicked',
            CTA: 'OmniChannelRequest slider closed',
            location: 'Estimator',
            action: '',
            field: '',
          });
          setOmniChannelRequest(true);
          setUrxForm(false);
          setCookie();
        }}
      >
        Contact Us
      </Button>
      <NoteDrawerRequest
        id={id}
        omniChannelRequest={OmniChannelRequest}
        setOmniChannelRequest={setOmniChannelRequest}
        setUrxForm={setUrxForm}
        environment={environment}
        instanceId={instanceId}
        formId={formId}
        formId2={formId2}
        moreinformation={false}
        urxlang={urxlang}
        bpid={bpid}
        pageTitle={pageTitle}
        object={object}
        location={location}
        tiles={tiles}
        setCookie={setCookie}
      />
    </div>
  );
};

export default OmniChannelRequest;
