import React from 'react';
import { Button, InlineNotification } from 'carbon-components-react';
import { ArrowRight16, Subtract32 } from '@carbon/icons-react';
import './NoteDrawerRequest.scss';
import '../../NS1PriceEstimator.scss';
import omniChannels from '../omnichannel.json';
import awsimage from '../public/aws.png';
import ibmimage from '../public/ibm.png';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { segmentsTracking } from 'src/common/lib/tracker';

export interface NoteDrawer {
  omniChannelRequest: boolean;
  setOmniChannelRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setUrxForm: React.Dispatch<React.SetStateAction<boolean>>;
  moreinformation: boolean;
  bpName?: string;
  logo?: string;
  id: string;
  environment: string;
  instanceId: string;
  formId: string;
  formId2: string;
  urxlang: IBMLocale;
  bpid?: string;
  pageTitle: string;
  object: string;
  location: string;
  resultValue?: string;
  ctaValue?: string;
  action?: string;
  field?: string;
  tiles: string;
  setCookie: () => void;
}

export default function NoteDrawer({
  id,
  setUrxForm,
  omniChannelRequest,
  setOmniChannelRequest,
  environment,
  urxlang,
  formId,
  formId2,
  instanceId,
  bpid,
  pageTitle,
  object,
  resultValue = '',
  ctaValue = '',
  action = '',
  field = '',
  location,
  tiles,
  setCookie,
}: NoteDrawer) {
  console.log(omniChannels);

  const closeDrawer = () => {
    setOmniChannelRequest(false);
    segmentsTracking('CTA Clicked', {
      pageTitle: pageTitle,
      object: object,
      resultValue: 'CTA clicked',
      CTA: 'ominichannel slider closed',
      location: location,
      action: action,
      field: field,
    });
  };

  return (
    <div id={id} className="ns1_pe">
      <div
        className={`widget-drawer-backdrop ${
          omniChannelRequest ? 'opened' : 'closed'
        } widget-drawer-transition`}
        onClick={closeDrawer}
      ></div>
      <div
        className={`widget-drawer ${
          omniChannelRequest ? 'opened' : 'closed'
        } widget-drawer-transition`}
      >
        <div className="widget-drawer-inner-container">
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
              <div className="widget-drawer-close-container">
                <Button
                  className="widget-drawer-close-btn"
                  kind="ghost"
                  renderIcon={Subtract32}
                  onClick={closeDrawer}
                ></Button>
              </div>
            </div>
          </div>
          <div className="widget-drawer-notes-container">
            <div className="bx--row">
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                <p className="slide_text widgetname">NS1 Connect</p>
              </div>
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                <p className="page_sub_heading">
                  Request a quote or live demo!
                </p>
              </div>
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 mt-02">
                <p className="page_body">
                  IBM NS1 Connect Managed DNS and traffic steering plans are
                  priced based upon query volume and DNS records. Our NS1
                  Essentials plan is available through IBM Marketplace and AWS
                  Marketplace. To learn more about our enterprise options,
                  contact sales. We also offer a developer account at no charge
                  for companies looking to try the solution first.
                </p>
              </div>

              <div className="cta-section">
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4"></div>
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                  <p className="page_body cta-text padding_24_2">
                    Engage with our sales team to obtain answers to your
                    questions, discuss your specific requirement, and receive a
                    personalized quote
                  </p>
                  <Button
                    renderIcon={ArrowRight16}
                    id="request_btn"
                    onClick={() => {
                      segmentsTracking('CTA Clicked', {
                        pageTitle: pageTitle,
                        object: object,
                        resultValue: 'CTA clicked - Omnichannel',
                        CTA: 'request a quote',
                        location: location,
                        action: action,
                        field: field,
                      });
                      initUrxForm(
                        environment,
                        instanceId,
                        formId,
                        urxlang as IBMLocale,
                        bpid,
                      );
                      setUrxForm(true);
                      setOmniChannelRequest(false);
                      setCookie();
                    }}
                  >
                    Request a quote
                  </Button>
                </div>
              </div>

              <div className="cta-section">
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 padding_24_2"></div>
                <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                  <p className="page_body cta-text">
                    Ready to see a demo in action? Click the "Request a demo"
                    button to get started!
                  </p>
                  <Button
                    className="padding_24_2"
                    kind="primary"
                    renderIcon={ArrowRight16}
                    id="request_btn"
                    onClick={() => {
                      segmentsTracking('CTA Clicked', {
                        pageTitle: pageTitle,
                        object: object,
                        resultValue: 'CTA clicked - Omnichannel',
                        CTA: 'request a demo',
                        location: location,
                        action: action,
                        field: field,
                      });
                      initUrxForm(
                        environment,
                        instanceId,
                        formId2,
                        urxlang as IBMLocale,
                        bpid,
                      );
                      setUrxForm(true);
                      setOmniChannelRequest(false);
                      setCookie();

                      // console.log(`Tiles - ${tiles}`);
                      // let editionId = '';
                      // if (tiles === 'Standard') {
                      //   editionId = '-';
                      // } else if (tiles === 'Essentials') {
                      //   editionId = 'EIDXX9VC';
                      // }
                      // window.open(
                      //   `https://www.ibm.com/marketplace/purchase/configuration/en/en/checkout?editionID=${editionId}&pricingtablecta=true`,
                      //   '_blank',
                      // );
                    }}
                  >
                    Request a live demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
