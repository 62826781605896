import { WidgetMetadataBasic } from '../../types/widgets';

// add any widget shortcode to this list that needs a direct pass-through of translations from AEM
export const widgetShortcodes = new Set<WidgetMetadataBasic['shortcode']>([
  'qradar-edr-estimator',
  'maas360-estimator',
  'ns1-roi',
  'ns1-pe',
  'spss-dynamic-pricing',
  'pa-roi',
  'watsonx-orchestrate-self-assessment',
  'ai-solutions-advisor',
  'aspera-file-transfer-calculator',
  'api-connect-price-estimator',
  'api-connect-bva-estimator',
  'instana-price-estimator',
  'omnichannel-panel',
  'sterling-bva-roi-estimator',
  'sterling-price-estimator',
  'mas-roi-estimator',
  'mas-saas-price-estimator',
  'guardium-insights',
  'cognos-price-estimator',
  'envizi-price-estimator',
  'eis-price-estimator',
  'watsonx-code-assistant-price-estimator',
  'watsonx-code-assistant-java-price-estimator',
  'spss-purchase-advisor-self-assessment',
]);

// add any widget shortcode to this list that needs a direct pass-through of translations from AEM
export const infraWidgetShortcodes = new Set<WidgetMetadataBasic['shortcode']>([
  'cyber-resilience-assessment',
  'power-disaster-recovery',
  'power-ai',
]);
