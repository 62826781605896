import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { useSmallBreakpoint } from 'src/common/hooks/widgets/useBreakpoint';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import { useCookiesURL } from 'src/common/hooks/widgets/useCookiesURL';
import * as URX_FORM from '../constants/urx-form';
import {
  EST_DEF_QUERIES,
  EST_MIN_QUERIES,
  EST_MAX_QUERIES,
  FREE_DEF_QUERIES,
  FREE_MIN_QUERIES,
  FREE_MAX_QUERIES,
  PREM_DEF_QUERIES,
  PREM_MIN_QUERIES,
  PREM_MAX_QUERIES,
  EST_DEF_RECORDS,
  EST_MIN_RECORDS,
  EST_MAX_RECORDS,
  FREE_DEF_RECORDS,
  FREE_MIN_RECORDS,
  FREE_MAX_RECORDS,
  PREM_DEF_RECORDS,
  PREM_MIN_RECORDS,
  PREM_MAX_RECORDS,
  EST_DEF_FILTERCHAINS,
  EST_MIN_FILTERCHAINS,
  EST_MAX_FILTERCHAINS,
  FREE_DEF_FILTERCHAINS,
  FREE_MIN_FILTERCHAINS,
  FREE_MAX_FILTERCHAINS,
  PREM_DEF_FILTERCHAINS,
  PREM_MIN_FILTERCHAINS,
  PREM_MAX_FILTERCHAINS,
  EST_DEF_MONITORS,
  EST_MIN_MONITORS,
  EST_MAX_MONITORS,
  FREE_DEF_MONITORS,
  FREE_MIN_MONITORS,
  FREE_MAX_MONITORS,
  PREM_DEF_MONITORS,
  PREM_MIN_MONITORS,
  PREM_MAX_MONITORS,
  PAPkg,
  UT30PASaaS,
} from '../constants/spread';
import { Helmet } from 'react-helmet';
import {
  Button,
  ButtonSet,
  InlineNotification,
  ModalWrapper,
  NumberInput,
  RadioTile,
  SkeletonPlaceholder,
  Tag,
  TileGroup,
  Tooltip,
  Accordion,
  AccordionItem,
  Toggle,
} from 'carbon-components-react';
import './NS1PriceEstimator.scss';
import 'src/common/style/common.scss';
import { usePrice } from '../hooks/usePrice';
import Cookies from 'js-cookie';
import Footnote from './Footnote';
import { FormModal } from 'src/common/utils/FormModal';
import Thanks from './Thanks';
import { segmentsTracking } from '../lib/tracker';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';
import { useBPName } from 'src/common/hooks/widgets/useBPName';
import countryList from '../services/currencies.json';
import BusinessPartner from 'src/common/components/BusinessPartner/BusinessPartner';
import {
  LangDefaults,
  determineCountry,
  getMessage,
  useLang,
} from 'src/common/hooks/widgets/useLang';
import DisclaimerText from 'src/common/components/DisclaimerText/DisclaimerText';
import {
  ArrowRight16,
  FlashFilled24,
  InformationFilled24,
} from '@carbon/icons-react';
import OmniChannel from './OmniChannel/OmniChannel';
import OmniChannelRequest from './OmniChannel/OmniChannelRequest';
import { getParamsFromUrl } from 'src/common/utils/getParamsFromUrl';
import { createUseTranslation } from '../../../common/hooks/widgets/useTranslation';
import { useNS1PESelector } from '../lib/redux/hooks';
import messages from '../locales/messages';

export interface NS1PriceEstimatorProps {
  langCode: string;
  test: boolean;
  countryToTest: string;
}

const NS1PriceEstimator = ({
  langCode,
  test,
  countryToTest,
}: NS1PriceEstimatorProps) => {
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const [urxlang] = useState(localeCode.toString());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currency, setCurrency] = useState('');
  const [country, setCountry] = useState('');
  const AEMMessages = useNS1PESelector((state) => state.translations.messages);
  const useTranslation = createUseTranslation(messages, AEMMessages);
  const [showPrice, setShowPrice] = useState(false);
  // lang and userlocale is based out of ddo.
  // lang and userlocale variable will be use once we start implmenting multilanguage
  const [lang, userLocale] = useLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry,
    localeCode,
  );

  const [localCurrency, setlocalCurrency] = useState<string>(localeCode);
  useEffect(() => {
    setlocalCurrency(userLocale); // show local Currency
  }, [userLocale, localeCode, localCurrency]);

  const [localExist, setLocalExist] = useState(false);
  let localStorageFound = localStorage.getItem('langLocale');
  if (localStorageFound === null)
    localStorageFound = LangDefaults.localCountryLang;
  const [selectedLocale, setSelectedLocale] = useState(localStorageFound);

  const [toggleCountry, setToggleCountry] = useState(false);
  const [modalLocalCountry, setModalLocalCountry] = useState('');
  const [modalLocalCurrency, setModalLocalCurrency] = useState('USD');
  const [modalLocalLanguage, setModalLocalLanguage] = useState('English');
  const [omnichannel, setOmniChannel] = useState(false);
  const [omnichannelRequest, setOmniChannelRequest] = useState(false);
  const [closeModal, setCloseModal] = useState(false);

  // Determine Country
  determineCountry(
    setLocalExist,
    setSelectedLocale,
    setModalLocalCountry,
    setModalLocalLanguage,
    setModalLocalCurrency,
    setToggleCountry,
    lang,
    countryList,
    true,
    test,
    localeCode,
  );

  const isSmall = useSmallBreakpoint('560px');
  const [environment] = useEnv();
  const [formComplete, setFormComplete] = useState(false);
  const [tileValueSelected, setTileValueSelected] = useState('essentials');
  const [packageName, setPackageName] = useState('Essentials');
  const [queriesInput, setQueriesInput] = useState(FREE_DEF_QUERIES);
  const [recordsInput, setRecordsInput] = useState(FREE_DEF_RECORDS);
  const [filterChainsInput, setFilterChainsInput] = useState(
    FREE_DEF_FILTERCHAINS,
  );
  const [monitorsInput, setMonitorsInput] = useState(FREE_DEF_MONITORS);
  const [RUM, setRUM] = useState(false);
  const [httpsRedirect, setHttpsRedirect] = useState(false);
  const [outageProtection, setOutageProtection] = useState(false);
  const [performanceChina, setPerformanceChina] = useState(false);
  const queriesRef = useRef() as MutableRefObject<HTMLInputElement>;
  const recordsRef = useRef() as MutableRefObject<HTMLInputElement>;
  const filterChainsRef = useRef() as MutableRefObject<HTMLInputElement>;
  const monitorsRef = useRef() as MutableRefObject<HTMLInputElement>;
  const cookieURL = useCookiesURL();
  const [notifToShow, setNotifToShow] = useState(false);
  const [totalCost, totalCostMonthly, loading] = usePrice({
    currency,
    country,
    queriesInput,
    recordsInput,
    filterChainsInput,
    monitorsInput,
    tileValueSelected,
    localeCode,
  });
  const { bpName, logo, companyId, desc } = useBPName(UT30PASaaS, true);
  const minQueries =
    tileValueSelected === 'essentials'
      ? EST_MIN_QUERIES
      : tileValueSelected === 'premium'
      ? PREM_MIN_QUERIES
      : FREE_MIN_QUERIES;

  const maxQueries =
    tileValueSelected === 'essentials'
      ? EST_MAX_QUERIES
      : tileValueSelected === 'premium'
      ? PREM_MAX_QUERIES
      : FREE_MAX_QUERIES;

  const minRecords =
    tileValueSelected === 'essentials'
      ? EST_MIN_RECORDS
      : tileValueSelected === 'premium'
      ? PREM_MIN_RECORDS
      : FREE_MIN_RECORDS;

  const maxRecords =
    tileValueSelected === 'essentials'
      ? EST_MAX_RECORDS
      : tileValueSelected === 'premium'
      ? PREM_MAX_RECORDS
      : FREE_MAX_RECORDS;

  const minFilterChains =
    tileValueSelected === 'essentials'
      ? EST_MIN_FILTERCHAINS
      : tileValueSelected === 'premium'
      ? PREM_MIN_FILTERCHAINS
      : FREE_MIN_FILTERCHAINS;

  const maxFilterChains =
    tileValueSelected === 'essentials'
      ? EST_MAX_FILTERCHAINS
      : tileValueSelected === 'premium'
      ? PREM_MAX_FILTERCHAINS
      : FREE_MAX_FILTERCHAINS;

  const minMonitors =
    tileValueSelected === 'essentials'
      ? EST_MIN_MONITORS
      : tileValueSelected === 'premium'
      ? PREM_MIN_MONITORS
      : FREE_MIN_MONITORS;

  const maxMonitors =
    tileValueSelected === 'essentials'
      ? EST_MAX_MONITORS
      : tileValueSelected === 'premium'
      ? PREM_MAX_MONITORS
      : FREE_MAX_MONITORS;

  const invalidQueries =
    parseInt(queriesRef?.current?.value) < minQueries ||
    parseInt(queriesRef?.current?.value) > maxQueries ||
    queriesRef?.current?.value === '';

  const invalidRecords =
    parseInt(recordsRef?.current?.value) < minRecords ||
    parseInt(recordsRef?.current?.value) > maxRecords ||
    recordsRef?.current?.value === '';

  const invalidFilterChains =
    parseInt(filterChainsRef?.current?.value) < minFilterChains ||
    parseInt(filterChainsRef?.current?.value) > maxFilterChains ||
    filterChainsRef?.current?.value === '';

  const invalidMonitors =
    parseInt(monitorsRef?.current?.value) < minMonitors ||
    parseInt(monitorsRef?.current?.value) > maxMonitors ||
    monitorsRef?.current?.value === '';

  const bannerStartDate = new Date(2024, 10, 1);
  const bannerExpDate = new Date(2024, 10, 31);
  const today = new Date();

  window.urxEnvironment = environment;
  window.onUrxFormSubmitSuccess = () => {
    setFormComplete(!formComplete);
    setDrawerOpen(false); // Hide Drawer on Thank you page
    setCloseModal(true);
  };

  useEffect(() => {
    setCookie(URX_FORM.FORM_ID);
  }, [
    queriesInput,
    recordsInput,
    filterChainsInput,
    monitorsInput,
    totalCostMonthly,
    totalCost,
    packageName,
  ]);

  useEffect(() => {
    if (tileValueSelected === 'premium') {
      setQueriesInput(PREM_DEF_QUERIES);
      setRecordsInput(PREM_DEF_RECORDS);
      setFilterChainsInput(PREM_DEF_FILTERCHAINS);
      setMonitorsInput(PREM_DEF_MONITORS);
    } else if (tileValueSelected === 'essentials') {
      setQueriesInput(EST_DEF_QUERIES);
      setRecordsInput(EST_DEF_RECORDS);
      setFilterChainsInput(EST_DEF_FILTERCHAINS);
      setMonitorsInput(EST_DEF_MONITORS);
    } else {
      setQueriesInput(FREE_DEF_QUERIES);
      setRecordsInput(FREE_DEF_RECORDS);
      setFilterChainsInput(FREE_DEF_FILTERCHAINS);
      setMonitorsInput(FREE_DEF_MONITORS);
    }
  }, [tileValueSelected]);

  useEffect(() => {
    if (tileValueSelected === 'essentials') {
      setShowPrice(true);
    } else {
      setShowPrice(false);
    }
  }, [queriesInput, recordsInput, monitorsInput, filterChainsInput]);

  const setCookie = (formId?: string) => {
    let useThisForm = URX_FORM.FORM_ID;
    if (formId) {
      useThisForm = formId;
    }
    Cookies.set(
      'urxdcq',
      JSON.stringify({
        source: useThisForm,
        // One field to provide all values (stringify)
        dcq: {
          Q_NOTESDATA:
            '|DIAC CI| NS1 Price Estimatr ' +
            cookieURL +
            ' Queries: ' +
            queriesInput +
            ' | Records: ' +
            recordsInput +
            ' | Filter chains: ' +
            filterChainsInput +
            ' | Monitors: ' +
            monitorsInput +
            ' | Package: ' +
            packageName +
            ' | Estimated Monthly cost:' +
            totalCostMonthly +
            ' | Estimated Annual cost:' +
            totalCost +
            ' | FormId:' +
            formId,
        },
      }),
    );
  };

  const handleTakeMe = () => {
    setShowPrice(false);
    if (tileValueSelected === 'essentials') {
      setTileValueSelected('premium');
      setPackageName('Premium');
    } else if (tileValueSelected === 'standard' && !showPrice) {
      setTileValueSelected('essentials');
      setPackageName('Essentials');
    }
    setNotifToShow(false);
    setRUM(false);
    setHttpsRedirect(false);
    setPerformanceChina(false);
    setOutageProtection(false);
  };

  const handleNotifShow = () => {
    if (tileValueSelected === 'standard') {
      if (
        Number(queriesRef.current.value) !== FREE_DEF_QUERIES ||
        Number(recordsRef.current.value) !== FREE_DEF_RECORDS ||
        Number(filterChainsRef.current.value) !== FREE_DEF_FILTERCHAINS ||
        Number(monitorsRef.current.value) !== FREE_DEF_MONITORS
      ) {
        setNotifToShow(true);
      } else if (
        Number(queriesRef.current.value) === FREE_DEF_QUERIES &&
        Number(recordsRef.current.value) === FREE_DEF_RECORDS &&
        Number(filterChainsRef.current.value) === FREE_DEF_FILTERCHAINS &&
        Number(monitorsRef.current.value) === FREE_DEF_MONITORS
      )
        setNotifToShow(false);
    } else if (tileValueSelected === 'essentials') {
      if (
        Number(queriesRef.current.value) >= maxQueries ||
        Number(recordsRef.current.value) === maxRecords ||
        Number(filterChainsRef.current.value) > EST_DEF_FILTERCHAINS ||
        Number(monitorsRef.current.value) > EST_DEF_MONITORS
      ) {
        setNotifToShow(true);
      } else if (
        Number(queriesRef.current.value) < maxQueries &&
        Number(recordsRef.current.value) === EST_DEF_RECORDS &&
        Number(filterChainsRef.current.value) === EST_DEF_FILTERCHAINS &&
        Number(monitorsRef.current.value) === EST_DEF_MONITORS
      ) {
        setNotifToShow(false);
      }
    }
  };
  const essentialsFeature = [
    String(useTranslation('est_feat_pt3', true)),
    String(useTranslation('est_feat_pt4', true)),
  ];

  const freeFeature = [
    String(useTranslation('std_feat_pt2', true)),
    String(useTranslation('std_feat_pt3', true)),
    String(useTranslation('std_feat_pt4', true)),
    String(useTranslation('est_feat_pt1', true)),
    String(useTranslation('est_feat_pt2', true)),
  ];

  const premiumFeature = [
    String(useTranslation('est_feat_pt5', true)),
    String(useTranslation('prem_feat_pt1', true)),
  ];

  const recommendText = useTranslation('recommend_text');
  const premNotificationLabel = useTranslation('prem_notif_label');

  const buttonRow = () => {
    if (tileValueSelected === 'standard') {
      return (
        <div>
          <ButtonSet className="ns1_pe__stack-button pa-mar4 gap-5">
            {tileValueSelected === 'standard' && !notifToShow && (
              <Button
                id="request_btn"
                renderIcon={ArrowRight16}
                target="_blank"
                data-ibm-contact="scheduler-link"
                href="https://my.nsone.net/#/account/signup"
                disabled={
                  tileValueSelected === 'standard' && notifToShow ? true : false
                }
                onClick={() => {
                  segmentsTracking('CTA Clicked', {
                    pageTitle: 'NS1 Price Estimator',
                    object: 'Pricing page',
                    resultValue: 'CTA clicked',
                    CTA: 'Sign up for free',
                    location: 'Estimator',
                    action: '',
                    field: '',
                  });
                }}
              >
                {useTranslation('signup_free_btn')}
              </Button>
            )}
            <Button
              kind="tertiary"
              id="demo_btn"
              renderIcon={ArrowRight16}
              onClick={() => {
                segmentsTracking('CTA Clicked', {
                  pageTitle: 'NS1 Pricing Page',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: 'Request a demo',
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
                initUrxForm(
                  environment,
                  URX_FORM.INSTANCE_ID,
                  URX_FORM.REQUEST_DEMO_FORM_ID,
                  urxlang as IBMLocale,
                  companyId,
                );
                setDrawerOpen(true);
                setCookie(URX_FORM.REQUEST_DEMO_FORM_ID);
              }}
            >
              {useTranslation('demo_button')}
            </Button>
          </ButtonSet>
        </div>
      );
    } else if (tileValueSelected === 'essentials') {
      return (
        <div>
          <ButtonSet className="ns1_pe__stack-button pa-mar5 gap-5">
            {RUM ||
              httpsRedirect ||
              outageProtection ||
              performanceChina ||
              (showPrice &&
                !notifToShow &&
                !invalidQueries &&
                !invalidMonitors &&
                !invalidFilterChains &&
                !invalidRecords && (
                  <OmniChannel
                    id="ns1-pricing-estimator"
                    omniChannel={omnichannel}
                    setUrxForm={setDrawerOpen}
                    setOmniChannel={setOmniChannel}
                    environment={environment}
                    instanceId={URX_FORM.INSTANCE_ID}
                    formId={URX_FORM.FORM_ID}
                    urxlang={urxlang as IBMLocale}
                    pageTitle="NS1 Pricing Page"
                    object="Pricing Page"
                    location={String(location)}
                    tiles={tileValueSelected}
                    setCookie={setCookie}
                  />
                ))}
            <OmniChannelRequest
              id="ns1-pricing-estimator"
              OmniChannelRequest={omnichannelRequest}
              setUrxForm={setDrawerOpen}
              setOmniChannelRequest={setOmniChannelRequest}
              environment={environment}
              instanceId={URX_FORM.INSTANCE_ID}
              formId={URX_FORM.FORM_ID}
              formId2={URX_FORM.REQUEST_DEMO_FORM_ID}
              urxlang={urxlang as IBMLocale}
              pageTitle={'NS1 Pricing Page'}
              object={'Pricing Page'}
              location={String(location)}
              tiles={tileValueSelected}
              setCookie={setCookie}
            />
          </ButtonSet>
        </div>
      );
    } else {
      return (
        <div>
          <ButtonSet className="ns1_pe__stack-button pa-mar5">
            <Button
              kind="primary"
              id="request_btn"
              renderIcon={ArrowRight16}
              onClick={() => {
                segmentsTracking('CTA Clicked', {
                  pageTitle: 'NS1 Pricing Page',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: 'Book a consultation',
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
                initUrxForm(
                  environment,
                  URX_FORM.INSTANCE_ID,
                  URX_FORM.FORM_ID,
                  urxlang as IBMLocale,
                  companyId,
                );
                setDrawerOpen(true);
                setCookie(URX_FORM.FORM_ID);
              }}
            >
              {useTranslation('quote_text')}
            </Button>
          </ButtonSet>
        </div>
      );
    }
  };
  {
    /* Section to render result table */
  }
  const resultTable = (tVSelected: string) => {
    return (
      <>
        <div className="bx--row">
          <div className="bx--col-lg-16 bx--col-md-8 bx--col--sd-4">
            {/* <Tag
              type="outline"
              className={
                tVSelected === 'essentials' || tVSelected === 'standard'
                  ? 'tag-margin1'
                  : 'tag-margin-prem'
              }
            >
              {useTranslation(
                tVSelected === 'essentials'
                  ? 'est_label'
                  : tVSelected === 'standard'
                  ? 'std_label'
                  : 'prem_label',
              )}
            </Tag> */}
          </div>
        </div>
        {tVSelected === 'essentials' ? (
          <div id="stickyNav">
            <div className="sticky-nav price_table_comp bg_white">
              <div className="mb-6">
                <div className="bx--row TabBorder">
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__heading">
                      {useTranslation('col1_text')}
                    </div>
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__heading">
                      {useTranslation('col2_text')}
                    </div>
                  </div>
                </div>
                <div className="bx--row TabBorder">
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__requirement price_table_comp_color padding_8">
                      {useTranslation('number_queries_text')}
                    </div>
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__quantity padding_8">{`${queriesInput}`}</div>
                  </div>
                </div>
                <div className="bx--row TabBorder">
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__requirement price_table_comp_color padding_8">
                      {useTranslation('number_records_text')}
                    </div>
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__quantity padding_8">
                      {`${recordsInput} `}
                    </div>
                  </div>
                </div>
                <div className="bx--row TabBorder">
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__requirement price_table_comp_color padding_8">
                      {useTranslation('number_filterchains_text')}
                    </div>
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__quantity padding_8">
                      {`${filterChainsInput} `}
                    </div>
                  </div>
                </div>
                <div className="bx--row TabBorder">
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__requirement price_table_comp_color padding_8">
                      {useTranslation('number_monitors_text')}
                    </div>
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                    <div className="price_table_comp__quantity padding_8">
                      {`${monitorsInput} `}
                    </div>
                  </div>
                </div>
              </div>
              {RUM ||
                httpsRedirect ||
                outageProtection ||
                performanceChina ||
                (showPrice &&
                  !notifToShow &&
                  !invalidQueries &&
                  !invalidMonitors &&
                  !invalidFilterChains &&
                  !invalidRecords && (
                    <>
                      {today <= bannerExpDate && today >= bannerStartDate && (
                        <div className="banner-content">
                          <p>
                            <InformationFilled24 />
                            {useTranslation('big_blue_30')}
                          </p>
                        </div>
                      )}
                      <div className="bx--row mb-6">
                        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sd-4">
                          <p
                            id="widget.estimated-cost"
                            className="ns1_pe__ibm-type-e estmt-cost price_table_comp__bodycopy w-estimated-cost"
                          >
                            {useTranslation('estimated_text')}
                            <sup className="ns1_pe__sup">1</sup>
                          </p>

                          <div className="padding_8 price_table_comp__price">
                            {loading ? (
                              <SkeletonPlaceholder className="ns1_pe__sticky-nav-skeleton" />
                            ) : (
                              totalCostMonthly
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bx--row">
                        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sd-4">
                          <p
                            id="widget.estimated-cost"
                            className="ns1_pe__ibm-type-e estmt-cost price_table_comp__bodycopy w-estimated-cost"
                          >
                            {useTranslation('estimated_annual_text')}
                            <sup className="ns1_pe__sup">1</sup>
                          </p>

                          <div className="padding_8 price_table_comp__price">
                            {loading ? (
                              <SkeletonPlaceholder className="ns1_pe__sticky-nav-skeleton" />
                            ) : (
                              totalCost
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bx--row">
                        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sd-4">
                          <p className="price_table_comp__disclaimer price_table_comp_color x-padding_24_1">
                            {`* `}
                            {useTranslation('prcie_text')}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
              {buttonRow()}
            </div>
          </div>
        ) : (
          <div id="stickyNav">
            <div className="sticky-nav price_table_comp bg_white">
              <div className="bx--row TabBorder">
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__heading">
                    {useTranslation('col1_text')}
                  </div>
                </div>
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__heading">
                    {useTranslation('col2_text')}
                  </div>
                </div>
              </div>
              <div className="bx--row TabBorder">
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__requirement price_table_comp_color padding_8">
                    {useTranslation('number_queries_text')}
                  </div>
                </div>
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__quantity padding_8">{`${queriesInput}`}</div>
                </div>
              </div>
              <div className="bx--row TabBorder">
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__requirement price_table_comp_color padding_8">
                    {useTranslation('number_records_text')}
                  </div>
                </div>
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__quantity padding_8">
                    {`${recordsInput} `}
                  </div>
                </div>
              </div>
              <div className="bx--row TabBorder">
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__requirement price_table_comp_color padding_8">
                    {useTranslation('number_filterchains_text')}
                  </div>
                </div>
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__quantity padding_8">
                    {`${filterChainsInput} `}
                  </div>
                </div>
              </div>
              <div className="bx--row TabBorder">
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__requirement price_table_comp_color padding_8">
                    {useTranslation('number_monitors_text')}
                  </div>
                </div>
                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sd-2">
                  <div className="price_table_comp__quantity padding_8">
                    {`${monitorsInput} `}
                  </div>
                </div>
              </div>
              {showPrice &&
                !invalidQueries &&
                !invalidMonitors &&
                !invalidFilterChains &&
                !invalidRecords && (
                  <>
                    <div className="bx--row">
                      <div className="bx--col-lg-16 bx--col-md-8 bx--col-sd-4">
                        <p
                          id="widget.estimated-cost"
                          className="ns1_pe__ibm-type-e estmt-cost price_table_comp__bodycopy padding_24_2"
                        >
                          {useTranslation('estimated_text')}
                          <sup className="ns1_pe__sup">1</sup>
                        </p>

                        <div className="padding_8 price_table_comp__price">
                          {loading ? (
                            <SkeletonPlaceholder className="ns1_pe__sticky-nav-skeleton" />
                          ) : (
                            totalCostMonthly
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bx--row">
                      <div className="bx--col-lg-16 bx--col-md-8 bx--col-sd-4">
                        <p
                          id="widget.estimated-cost"
                          className="ns1_pe__ibm-type-e estmt-cost price_table_comp__bodycopy"
                        >
                          {useTranslation('estimated_annual_text')}
                          <sup className="ns1_pe__sup">1</sup>
                        </p>

                        <div className="padding_8 price_table_comp__price">
                          {loading ? (
                            <SkeletonPlaceholder className="ns1_pe__sticky-nav-skeleton" />
                          ) : (
                            totalCost
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bx--row">
                      <div className="bx--col-lg-16 bx--col-md-8 bx--col-sd-4">
                        <p className="price_table_comp__disclaimer price_table_comp_color padding_24_1">
                          {`* `}
                          {useTranslation('prcie_text')}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              {buttonRow()}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="ns1_pe widget-styles">
      <Helmet>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/loader.js`}
        ></script>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/main.js`}
        ></script>
      </Helmet>
      {formComplete ? (
        <Thanks
          queriesInput={queriesInput}
          recordsInput={recordsInput}
          filterChainsInput={filterChainsInput}
          monitorsInput={monitorsInput}
          licensePriceMonthly={`${totalCostMonthly}`}
          licensePriceYearly={`${totalCost}`}
          packageName={packageName}
          bpName={bpName}
        />
      ) : (
        <div id="PASaaSGrid">
          {bpName ? (
            <BusinessPartner bpName={bpName} logo={logo} desc={desc} />
          ) : null}
          <div className="bx--row">
            <div
              className="bx--col-lg-3 bx--col-md-8 bx--col-sm-8 bx--offset-lg-13 margintop_1 pa-mar8"
              id="countryModal"
            >
              {/* Currently switched off - false to be removed once globalised */}

              {localExist && toggleCountry ? (
                <ModalWrapper
                  modalLabel={`Found country ${modalLocalCountry}. Access page in ${modalLocalLanguage} (${modalLocalCurrency}) `}
                  modalHeading={`The NS1 price estimator is available in ${modalLocalLanguage} with local currency ${modalLocalCurrency}`}
                  size={'sm'}
                  primaryButtonText={'Cool! Take me there'}
                  secondaryButtonText={''}
                  buttonTriggerText={`Access page in ${modalLocalLanguage}`}
                  handleOpen={() => {
                    segmentsTracking('CTA Clicked', {
                      pageTitle: 'NS1 Pricing Page',
                      object: 'Pricing page',
                      resultValue: 'CTA clicked',
                      CTA: 'Access Button for globalization',
                      location: 'Estimator',
                      action: '',
                      field: '',
                    });
                  }}
                  handleSubmit={() => {
                    localStorage.setItem('langLocale', selectedLocale);
                    (window.location.href =
                      'https://www.ibm.com/' +
                      selectedLocale +
                      '/products/ns1-connect/pricing'),
                      segmentsTracking('CTA Clicked', {
                        pageTitle: 'NS1 Pricing Page',
                        object: 'Pricing page',
                        resultValue: 'Submit button for globalization',
                        CTA: 'Submit button for globalization',
                        location: 'Estimator',
                        action: '',
                        field: '',
                      });
                    return true;
                  }}
                ></ModalWrapper>
              ) : null}
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
              <div className="page_sub_heading mb-6">
                {useTranslation('ns1_headline')}
              </div>
              <div className="bx--row">
                <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
                  <TileGroup
                    className="ns1_pe__tilegroup"
                    name="radio tile group"
                    valueSelected={tileValueSelected}
                  >
                    <RadioTile
                      id="radio-tile-2-ns1-pe"
                      value="standard"
                      className="ns1_pe__tile"
                      onClick={() => {
                        setNotifToShow(false);
                        setTileValueSelected('standard');
                        setPackageName('Free Developer');
                        setRUM(false);
                        setHttpsRedirect(false);
                        setPerformanceChina(false);
                        setOutageProtection(false);
                        segmentsTracking('CTA Clicked', {
                          pageTitle: 'NS1 Pricing Page',
                          object: 'Pricing page',
                          resultValue: 'CTA clicked',
                          CTA: 'Free developer Tile',
                          location: 'Estimator',
                          action: '',
                          field: '',
                        });
                      }}
                    >
                      {country === 'BRA' ? (
                        <div className="page_sub_heading mar9">
                          {PAPkg.pkg2}
                        </div>
                      ) : (
                        <div className="page_sub_heading mar9">
                          {useTranslation('std_label')}
                        </div>
                      )}
                      <div className="page_body_2">
                        {useTranslation('std_pkg_desc')}
                      </div>
                    </RadioTile>
                    <RadioTile
                      className="ns1_pe__tile"
                      id="radio-tile-1-ns1-pe"
                      value="essentials"
                      onClick={() => {
                        setNotifToShow(false);
                        setTileValueSelected('essentials');
                        setPackageName('Essentials');
                        setRUM(false);
                        setHttpsRedirect(false);
                        setPerformanceChina(false);
                        setOutageProtection(false);
                        segmentsTracking('CTA Clicked', {
                          pageTitle: 'NS1 Pricing Page',
                          object: 'Pricing page',
                          resultValue: 'CTA clicked',
                          CTA: 'Essentials Tile',
                          location: 'Estimator',
                          action: '',
                          field: '',
                        });
                      }}
                    >
                      {country === 'BRA' ? (
                        <div className="page_sub_heading mar9">
                          {PAPkg.pkg1}
                        </div>
                      ) : (
                        <div className="page_sub_heading mar9">
                          {useTranslation('est_label')}
                        </div>
                      )}
                      <div className="page_body_2">
                        {useTranslation('est_pkg_desc')}
                        {today <= bannerExpDate && today >= bannerStartDate && (
                          <div className="banner-content">
                            <p>
                              <InformationFilled24 />
                              {useTranslation('big_blue_30_short')}
                            </p>
                          </div>
                        )}
                      </div>
                    </RadioTile>
                    <RadioTile
                      id="radio-tile-3-ns1-pe"
                      value="premium"
                      onClick={() => {
                        setNotifToShow(false);
                        setShowPrice(false);
                        setTileValueSelected('premium');
                        setPackageName('Premium');
                        setRUM(false);
                        setHttpsRedirect(false);
                        setPerformanceChina(false);
                        setOutageProtection(false);
                        segmentsTracking('CTA Clicked', {
                          pageTitle: 'NS1 Pricing Page',
                          object: 'Pricing page',
                          resultValue: 'CTA clicked',
                          CTA: 'Premium Tile',
                          location: 'Estimator',
                          action: '',
                          field: '',
                        });
                      }}
                    >
                      {country === 'BRA' ? (
                        <div className="page_sub_heading mar9">
                          {PAPkg.pkg3}
                        </div>
                      ) : (
                        <div className="page_sub_heading mar9">
                          {useTranslation('prem_label')}
                        </div>
                      )}
                      <div className="page_body_2">
                        {useTranslation('prem_pkg_desc')}
                      </div>
                    </RadioTile>
                  </TileGroup>
                </div>
              </div>
              {/* User Part */}
              <div className="bx--row">
                <div className="bx--col-lg-9 bx--col-md-16 bx--col-sm-16 pa-mar2">
                  <div className="pa-mar6 mb-6">
                    {tileValueSelected === 'essentials' ? (
                      <>
                        <div className="page_body_2 marginleft_1 mb-3">
                          {useTranslation('std_feat_label')}:
                        </div>
                        <div className="marginleft_1">
                          {essentialsFeature.map(
                            (item: string, index: number) => (
                              <Tag
                                key={index}
                                type={'purple'}
                                size="md"
                                disabled={false}
                                filter={false}
                                className="tag-margin"
                              >
                                {item}
                              </Tag>
                            ),
                          )}
                        </div>
                      </>
                    ) : tileValueSelected === 'standard' ? (
                      <div className="marginleft_1">
                        <div className="page_body_2">
                          {useTranslation('est_feat_label')}:
                        </div>
                        {freeFeature.map((item: string, index: number) => (
                          <Tag
                            key={index}
                            type={'purple'}
                            size="md"
                            disabled={false}
                            filter={false}
                            className="tag-margin"
                          >
                            {item}
                          </Tag>
                        ))}
                      </div>
                    ) : (
                      <div className="marginleft_1">
                        <div className="page_body_2">
                          {useTranslation('prem_feat_label')}:
                        </div>
                        {premiumFeature.map((item: string, index) => (
                          <Tag
                            key={index}
                            type={'purple'}
                            size="md"
                            disabled={false}
                            filter={false}
                            className="tag-margin"
                          >
                            {item}
                          </Tag>
                        ))}
                      </div>
                    )}
                  </div>
                  {tileValueSelected === 'essentials' && notifToShow ? (
                    <div className="bx--row pa-mar6">
                      <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
                        <InlineNotification
                          title={recommendText}
                          kind={'info-square'}
                          hideCloseButton={true}
                          actions={
                            <p
                              className="notification-text"
                              onClick={handleTakeMe}
                            >
                              {useTranslation('prem_notif_txt')}
                            </p>
                          }
                        />
                      </div>
                    </div>
                  ) : tileValueSelected === 'standard' && notifToShow ? (
                    <div className="bx--row pa-mar6">
                      <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
                        <InlineNotification
                          title={recommendText}
                          kind={'info-square'}
                          hideCloseButton={true}
                          actions={
                            <p
                              className="notification-text"
                              onClick={handleTakeMe}
                            >
                              {useTranslation('std_notif_txt')}
                            </p>
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="bx--row mb-6">
                    <div className="bx--col-lg-8 bx--col-md-16 bx--col-sm-16 marginleft_1">
                      <br />
                      <Tooltip
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'DNS queries tooltip',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                        }}
                        direction={isSmall ? 'bottom' : 'right'}
                        tabIndex={0}
                        triggerText={
                          <span className="page_body mb-3">
                            {useTranslation('number_queries_text')}
                          </span>
                        }
                      >
                        <span className="slide_text">
                          {useTranslation('tooltip_queries_text')}
                        </span>
                      </Tooltip>
                      <NumberInput
                        id="queries"
                        size="md"
                        step={tileValueSelected === 'standard' ? 1 : 10}
                        allowEmpty={true}
                        ref={queriesRef}
                        onKeyDown={(e) => {
                          tileValueSelected === 'standard' &&
                            e.preventDefault();
                        }}
                        invalidText={
                          tileValueSelected === 'essentials'
                            ? useTranslation('est_invalid_queries_text')
                            : tileValueSelected === 'standard'
                            ? useTranslation('std_invalid_queries_text')
                            : useTranslation('prem_invalid_queries_text')
                        }
                        className="number-input"
                        helperText={
                          tileValueSelected === 'essentials' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('est_queries_info1')}
                              </p>
                            </div>
                          ) : tileValueSelected === 'standard' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('std_queries_info1')}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <p className="helper-text">
                                {useTranslation('prem_queries_info1')}
                              </p>
                              <p className="helper-text">
                                {useTranslation('prem_queries_info2')}
                              </p>
                            </div>
                          )
                        }
                        min={minQueries}
                        max={maxQueries}
                        value={queriesInput}
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'Total DNS queries in millions',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                          setQueriesInput(Number(queriesRef.current.value));
                          handleNotifShow();
                        }}
                      />
                    </div>
                    <div className="bx--col-lg-7 bx--col-md-16 bx--col-sm-16 marginleft_1">
                      <br />
                      <Tooltip
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'DNS records tooltip',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                        }}
                        direction={isSmall ? 'bottom' : 'right'}
                        tabIndex={0}
                        triggerText={
                          <span className="page_body mb-3">
                            {useTranslation('number_records_text')}
                          </span>
                        }
                      >
                        <span className="slide_text">
                          {useTranslation('tooltip_records_text')}
                        </span>
                      </Tooltip>
                      <NumberInput
                        id="records"
                        size="md"
                        allowEmpty={true}
                        ref={recordsRef}
                        step={tileValueSelected === 'standard' ? 1 : 100}
                        onKeyDown={(e) => {
                          tileValueSelected === 'standard' &&
                            e.preventDefault();
                          tileValueSelected === 'essentials' &&
                            e.preventDefault();
                        }}
                        invalidText={
                          tileValueSelected === 'essentials'
                            ? useTranslation('est_invalid_records_text')
                            : tileValueSelected === 'standard'
                            ? useTranslation('std_invalid_records_text')
                            : useTranslation('prem_invalid_records_text')
                        }
                        helperText={
                          tileValueSelected === 'essentials' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('est_records_info1')}
                              </p>
                            </div>
                          ) : tileValueSelected === 'standard' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('std_records_info1')}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <p className="helper-text">
                                {useTranslation('prem_records_info1')}
                              </p>
                              <p className="helper-text">
                                {useTranslation('prem_records_info2')}
                              </p>
                            </div>
                          )
                        }
                        min={minRecords}
                        max={maxRecords}
                        value={recordsInput}
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'Total DNS records',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                          setRecordsInput(Number(recordsRef.current.value));
                          handleNotifShow();
                        }}
                      />
                    </div>
                  </div>
                  <div className="bx--row mb-6">
                    <div className="bx--col-lg-8 bx--col-md-16 bx--col-sm-16 marginleft_1">
                      <br />
                      <Tooltip
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'Filter chain tooltip',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                        }}
                        direction={isSmall ? 'bottom' : 'right'}
                        tabIndex={0}
                        triggerText={
                          <span className="page_body mb-3">
                            {useTranslation('number_filterchains_text')}
                          </span>
                        }
                      >
                        <span className="slide_text">
                          {useTranslation('tooltip_filterchain_text')}
                        </span>
                      </Tooltip>
                      <NumberInput
                        id="filter"
                        size="md"
                        allowEmpty={true}
                        ref={filterChainsRef}
                        onKeyDown={(e) => {
                          tileValueSelected === 'standard' &&
                            e.preventDefault();
                          tileValueSelected === 'essentials' &&
                            e.preventDefault();
                        }}
                        invalidText={
                          tileValueSelected === 'essentials'
                            ? useTranslation('est_invalid_filter_chains_text')
                            : tileValueSelected === 'standard'
                            ? useTranslation('std_invalid_filter_chains_text')
                            : useTranslation('prem_invalid_filter_chains_text')
                        }
                        className="number-input"
                        helperText={
                          tileValueSelected === 'essentials' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('est_filter_chains_info1')}
                              </p>
                            </div>
                          ) : tileValueSelected === 'standard' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('std_filter_chains_info1')}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <p className="helper-text">
                                {useTranslation('prem_filter_chains_info1')}
                              </p>
                              <p className="helper-text">
                                {useTranslation('prem_filter_chains_info2')}
                              </p>
                            </div>
                          )
                        }
                        min={minFilterChains}
                        max={maxFilterChains}
                        value={filterChainsInput}
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'Total Filter Chains',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                          setFilterChainsInput(
                            Number(filterChainsRef.current.value),
                          );
                          handleNotifShow();
                        }}
                      />
                    </div>
                    <div className="bx--col-lg-7 bx--col-md-16 bx--col-sm-16 marginleft_1">
                      <br />
                      <Tooltip
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'Monitors tooltip',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                        }}
                        direction={isSmall ? 'bottom' : 'right'}
                        tabIndex={0}
                        triggerText={
                          <span className="page_body mb-3">
                            {useTranslation('number_monitors_text')}
                          </span>
                        }
                      >
                        <span className="slide_text">
                          {useTranslation('tooltip_monitors_text')}
                        </span>
                      </Tooltip>
                      <NumberInput
                        id="monitors"
                        size="md"
                        allowEmpty={true}
                        ref={monitorsRef}
                        step={1}
                        onKeyDown={(e) => {
                          tileValueSelected === 'standard' &&
                            e.preventDefault();
                          tileValueSelected === 'essentials' &&
                            e.preventDefault();
                        }}
                        invalidText={
                          tileValueSelected === 'essentials'
                            ? useTranslation('est_invalid_monitors_text')
                            : tileValueSelected === 'standard'
                            ? useTranslation('std_invalid_monitors_text')
                            : useTranslation('prem_invalid_monitors_text')
                        }
                        helperText={
                          tileValueSelected === 'essentials' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('est_monitors_info1')}
                              </p>
                            </div>
                          ) : tileValueSelected === 'standard' ? (
                            <div>
                              <p className="helper-text">
                                {useTranslation('std_monitors_info1')}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <p className="helper-text">
                                {useTranslation('prem_monitors_info1')}
                              </p>
                              <p className="helper-text">
                                {useTranslation('prem_monitors_info2')}
                              </p>
                            </div>
                          )
                        }
                        min={minMonitors}
                        max={maxMonitors}
                        value={monitorsInput}
                        onChange={() => {
                          segmentsTracking('CTA Clicked', {
                            pageTitle: 'NS1 Pricing Page',
                            object: 'Pricing page',
                            resultValue: 'CTA clicked',
                            CTA: 'Total Monitors',
                            location: 'Estimator',
                            action: '',
                            field: '',
                          });
                          setMonitorsInput(Number(monitorsRef.current.value));
                          handleNotifShow();
                        }}
                      />
                    </div>
                  </div>
                  {tileValueSelected === 'essentials' &&
                  (RUM ||
                    httpsRedirect ||
                    outageProtection ||
                    performanceChina) ? (
                    <div className="bx--row pa-mar6">
                      <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
                        <InlineNotification
                          title={recommendText}
                          kind={'info-square'}
                          hideCloseButton={true}
                          actions={
                            <p
                              className="notification-text"
                              onClick={handleTakeMe}
                            >
                              {useTranslation('prem_notif_txt')}
                            </p>
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {(tileValueSelected === 'essentials' ||
                    tileValueSelected === 'premium') && (
                    <Accordion size={'lg'} className="pl-5">
                      <AccordionItem
                        open={true}
                        className="pa-mar1"
                        title={
                          <p className="page_body_2">
                            {useTranslation('advanced_options_title')}
                          </p>
                        }
                      >
                        {/* Rum Toggle */}
                        <div className="bx--row pa-mar6 pa-mar1">
                          <div className="bx--col-lg-14 bx--col-md-14 bx--col-sm-14">
                            <p className="page_body_2">
                              {useTranslation('rum_toggle')}
                            </p>
                          </div>
                          <div className="bx--col-lg-2 bx--col-md-2 bx--col-sm-2">
                            <Toggle
                              className="remove_label_space"
                              labelA={String(useTranslation('no', true))}
                              labelB={String(useTranslation('yes', true))}
                              id="rum-toggle-id"
                              toggled={RUM}
                              onChange={() => {
                                setRUM(!RUM);
                                segmentsTracking('CTA Clicked', {
                                  pageTitle: 'API Connect Pricing Page',
                                  object: 'Pricing page',
                                  resultValue: 'CTA clicked',
                                  CTA: 'RUM Toggled',
                                  location: 'Estimator',
                                  action: '',
                                  field: '',
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="bx--row pa-mar6 pa-mar1">
                          <div className="bx--col-lg-14 bx--col-md-14 bx--col-sm-14">
                            <p className="page_body_2">
                              {useTranslation('https_redirect_toggle')}
                            </p>
                          </div>
                          <div className="bx--col-lg-2 bx--col-md-2 bx--col-sm-2">
                            <Toggle
                              className="remove_label_space"
                              labelA={String(useTranslation('no', true))}
                              labelB={String(useTranslation('yes', true))}
                              id="redirect-toggle-id"
                              toggled={httpsRedirect}
                              onChange={() => {
                                segmentsTracking('CTA Clicked', {
                                  pageTitle: 'API Connect Pricing Page',
                                  object: 'Pricing page',
                                  resultValue: 'CTA clicked',
                                  CTA: 'HTTPS redirect Toggled',
                                  location: 'Estimator',
                                  action: '',
                                  field: '',
                                });
                                setHttpsRedirect(!httpsRedirect);
                              }}
                            />
                          </div>
                        </div>
                        <div className="bx--row pa-mar6 pa-mar1">
                          <div className="bx--col-lg-14 bx--col-md-14 bx--col-sm-14">
                            <p className="page_body_2">
                              {useTranslation('outage_protection_toggle')}
                            </p>
                          </div>
                          <div className="bx--col-lg-2 bx--col-md-2 bx--col-sm-2">
                            <Toggle
                              className="remove_label_space"
                              labelA={String(useTranslation('no', true))}
                              labelB={String(useTranslation('yes', true))}
                              id="outage=protection-toggle-id"
                              toggled={outageProtection}
                              onChange={() => {
                                segmentsTracking('CTA Clicked', {
                                  pageTitle: 'API Connect Pricing Page',
                                  object: 'Pricing page',
                                  resultValue: 'CTA clicked',
                                  CTA: 'Outage/Downtime Protection Toggled',
                                  location: 'Estimator',
                                  action: '',
                                  field: '',
                                });
                                setOutageProtection(!outageProtection);
                              }}
                            />
                          </div>
                        </div>
                        <div className="bx--row pa-mar6 pa-mar1">
                          <div className="bx--col-lg-14 bx--col-md-14 bx--col-sm-14">
                            <p className="page_body_2">
                              {useTranslation('preformance_china_toggle')}
                            </p>
                          </div>
                          <div className="bx--col-lg-2 bx--col-md-2 bx--col-sm-2">
                            <Toggle
                              className="remove_label_space"
                              labelA={String(useTranslation('no', true))}
                              labelB={String(useTranslation('yes', true))}
                              id="performance-china-toggle-id"
                              toggled={performanceChina}
                              onChange={() => {
                                segmentsTracking('CTA Clicked', {
                                  pageTitle: 'API Connect Pricing Page',
                                  object: 'Pricing page',
                                  resultValue: 'CTA clicked',
                                  CTA: 'Performance in mainloand China Toggled',
                                  location: 'Estimator',
                                  action: '',
                                  field: '',
                                });
                                setPerformanceChina(!performanceChina);
                              }}
                            />
                          </div>
                        </div>
                      </AccordionItem>
                    </Accordion>
                  )}
                  {tileValueSelected && (
                    <div id="footnote" className="div-only-lg">
                      <div className="bx--row">
                        <div className="bx--col-lg-16 marginleft_1">
                          <div>
                            <Footnote numNote={1} />
                          </div>
                          <div className="footnote_text">
                            <DisclaimerText
                              localeCode={localeCode}
                              product={'NS1 Price Estimator'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="bx--col-lg-5 bx--col-md-8 bx--col-sm-4">
                  {resultTable(tileValueSelected)}
                  {/* {tileValueSelected !== 'premium' && (
                    <div id="footnote" className="div-only-media">
                      <div className="bx--row pa-mar6">
                        <div className="bx--col-lg-9">
                          <div>
                            <Footnote numNote={1} />
                          </div>
                          <div className="footnote_text">
                            <DisclaimerText
                              localeCode={localeCode}
                              product={'NS1 Price Estimator'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                  <FormModal
                    pageTitle="NS1 Pricing Page"
                    object="NS1 Pricing Page"
                    productTitle={'NS1 PE'}
                    pageUrl={''}
                    location={'Estimator'}
                    bpName={bpName}
                    logo={logo}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    setCloseModal={setCloseModal}
                    closeModal={closeModal}
                  ></FormModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NS1PriceEstimator;
